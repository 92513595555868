import Cookies from 'js-cookie';
import * as types from '../types';

const initialState = {
  dates: {
    startDate: '',
    endDate: '',
  },
  depth: {
    startDepth: null,
    endDepth: null,
  },
  data: {},
  zoomRangeData: {},
  chartMode: 'time',
  range: '1 hours',
  filteredDates: null,
  hasFilteredDatesChange: false,
  isHideAnnotation: Cookies.get("isHideAnnoTations") || false
};

export default function wellProfileReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_WELL_PROFILES_DATES:
      // eslint-disable-next-line no-case-declarations
      const { startDate, endDate } = payload;
      return {
        ...state,
        dates: {
          startDate,
          endDate,
        },
      };
    case types.SET_WELL_PROFILES_DEPTH:
      // eslint-disable-next-line no-case-declarations
      const { startDepth, endDepth } = payload;
      return {
        ...state,
        depth: {
          startDepth,
          endDepth,
        },
      };
    case types.SET_WELL_PROFILE_CHART_MODE:
      return {
        ...state,
        chartMode: payload,
      };
    case types.SET_WELL_PROFILES_DATA:
      return {
        ...state,
        data: payload,
      };
    case types.SET_ZOOM_RANGE_DATA:
      return {
        ...state,
        zoomRangeData: payload,
      };
    case types.SET_WELL_PROFILE_FILTERED_RANGE:
      return {
        ...state,
        range: payload,
      };
    case types.SET_WELL_PROFILE_FILTERED_DATES:
      return {
        ...state,
        filteredDates: payload,
      };
    case types.IS_RANGE_FILTERED_DATE_CHANGED:
      return {
        ...state,
        hasFilteredDatesChange: payload,
      };
    case types.TOGGLE_HIDE_ANNOTATATIONS:
      return {
        ...state,
        isHideAnnotation: payload,
      };
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
