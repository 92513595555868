import React from 'react';

const Icon = ({size, color}) => (
  <svg width={size || "24"} height={size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 19.5C15.4706 19.5 19.5 15.4706 19.5 10.5C19.5 5.52944 15.4706 1.5 10.5 1.5C5.52944 1.5 1.5 5.52944 1.5 10.5C1.5 15.4706 5.52944 19.5 10.5 19.5Z"
      stroke={color || "#1C1C1C"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M17.25 17.25L21.5 21.5M7.5 10.5H13.5" stroke={color || "#1C1C1C"} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Icon;
